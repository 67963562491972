import React, { useEffect, useState } from "react";
import "./hallOfFamePage.css";
import Button from "@mui/material/Button";
import HallOfFame2021 from "./hallOfFame2021";
import HallOfFame2022 from "./hallOfFame2022";
import HallOfFame2023 from "./hallOfFame2023";
import HallOfFame2024 from "./hallOfFame2024";

const years = [2024, 2023, 2022, 2021];

export default function HallOfFamePage() {
  const [state, setStateValues] = useState({
    selectedUser: null,
    selectedYear: 2023,
  });

  const setState = (obj) => {
    setStateValues({
      ...state,
      ...obj,
    });
  };

  const onShowFull = (selectedUser) => {
    setState({ selectedUser });
  };

  const onModalClose = () => {
    setState({ selectedUser: null });
  };

  const selectYear = (y) => {
    setState({ selectedYear: y });
  };

  const yearButtons = (
    <div className="yearSelectionBox">
      {years.map((y) => (
        <Button
          color="secondary"
          style={{ marginLeft: 3, marginRight: 3, marginBottom: 6 }}
          variant="outlined"
          key={"year_button" + y}
          onClick={() => selectYear(y)}
        >
          {"Oyun " + y}
        </Button>
      ))}
    </div>
  );

  return (
    <div>
      {yearButtons}
      {state.selectedYear === 2021 && (
        <HallOfFame2021
          onShowFull={onShowFull}
          onModalClose={onModalClose}
          selectedUser={state.selectedUser}
        />
      )}

      {state.selectedYear === 2022 && (
        <HallOfFame2022
          onShowFull={onShowFull}
          onModalClose={onModalClose}
          selectedUser={state.selectedUser}
        />
      )}
      {state.selectedYear === 2023 && (
        <HallOfFame2023
          onShowFull={onShowFull}
          onModalClose={onModalClose}
          selectedUser={state.selectedUser}
        />
      )}
      {state.selectedYear === 2024 && (
        <HallOfFame2024
          onShowFull={onShowFull}
          onModalClose={onModalClose}
          selectedUser={state.selectedUser}
        />
      )}
    </div>
  );
}
